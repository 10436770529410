<template>
  <div class="brands-page" v-if="brandsPageData">
    <section class="brands-page__hero">
      <img
        class="brands-page__hero-img"
        :src="heroImage"
        loading="lazy"
        :alt="brandsPageData.imageAlt"
        :title="brandsPageData.imageDescription"
      >
      <div class="brands-page__hero-content">
        <div class="brands-page__hero-content-inner">
          <h1
            class="brands-page__title text-h1-capitalized"
            v-if="brandsPageData.title"
            v-html="brandsPageData.title"
          ></h1>
          <p
            class="brands-page__subtitle paragraph"
            v-if="brandsPageData.paragraph"
            v-html="brandsPageData.paragraph"
          ></p>
        </div>
      </div>
    </section>
    <section class="brands-page__content page-section">
      <div class="container">
        <div class="brands-page__content-header">
          <h2 class="brands-page__section-titile">Brands</h2>
        </div>

        <div class="brands-list">
          <div
            class="brands-item"
            v-for="(item, idx) in visibleBrands"
            :key="item.title+idx"
          >
            <router-link
              class="brands-item__link"
              :to="item.url"
            >
              <img
                class="brands-item__logo"
                :src="item.logo"
                :alt="item.imageAlt || item.originalTitle"
                :title="item.imageDescription"
                loading="lazy"
              />
            </router-link>
          </div>
        </div>

        <div class="brands-page__load-wrap">
          <button
            class="button button--outlined"
            v-if="isShowAllBtnVisible"
            @click="showMoreBrands"
          >LOAD MORE</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import PageMixin from '@/mixins/PageMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'Brands',
  mixins: [PageMixin, ScreenWidthMixin],
  methods: {
    ...mapActions('brands', ['setBrandsPageData']),
    showMoreBrands () {
      this.showAllItemsClicked = true
    }
  },
  computed: {
    ...mapState('brands', ['brandsPageData']),
    visibleBrands () {
      return this.showAllItemsClicked || this.brandsPageData?.brandsList?.length <= 20
        ? this.brandsPageData.brandsList
        : this.brandsPageData.brandsList.slice(0, 12)
    },
    isShowAllBtnVisible () {
      return this.brandsPageData?.brandsList?.length > 20 && this.visibleBrands.length < this.brandsPageData?.brandsList?.length
    },
    heroImage () {
      return this.isMobile && this.brandsPageData.imageMobile ? this.brandsPageData.imageMobile : this.brandsPageData.image
    },
    metaData () {
      return this.brandsPageData?.meta
    },
    pageTitle () {
      return this.brandsPageData?.meta?.title || this.$route.meta?.title
    }
  },
  async created () {
    if (!this.brandsPageData) await this.setBrandsPageData()
    this.$nextTick(() => {
      this.setPageTitle(this.pageTitle)
      this.setMetaTags()
    })
  },
  beforeDestroy () {
    this.unsetMetaTags()
  },
  data () {
    return {
      showAllItemsClicked: false
    }
  }
}
</script>
